<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">签约司机</div>
				<div class="header-button">
					<!-- <el-button
						v-permission="'pc/driver-center/driver-manage/save'"
						type="success"
						@click="onCreate"
					>
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button> -->
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/driver-center/driver-manage/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="signingColumns"
					:data="tableData"
					:params="{ page }"
					autoWidth
				>
					<template #status="{ value }">
						<span>{{ mapDict('driver_status', value) }}</span>
					</template>
					<template #orderStatus="{ value }">
						<span>{{ mapDict('order_status', value) }}</span>
					</template>
					<template #sex="{ value }">
						<span>{{ mapDict('sex', value) }}</span>
					</template>
					<template #name="{ value, row }">
						<span class="cursor" @click="onDetail(row)">{{ value || '--' }}</span>
					</template>
					<template #operation="{ value, row }">
						<span
							v-permission="'pc/driver-center/driver-manage/edit'"
							class="cursor"
							@click="onEdit(row)"
						>
							编辑
						</span>
						<span
							v-permission="'pc/driver-center/driver-manage/edit'"
							class="cursor"
							style="margin-left: 8px"
							@click="onEditMobile(row)"
						>
							修改手机
						</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<driver-form ref="formRef" @on-refresh="fetchList" />
		<driver-detail ref="detailRef" @on-refresh="fetchList" />
		<mobile-form ref="mobileRef" @on-refresh="fetchList" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'DriverSigning',
})
</script>

<script setup name="DriverSigning">
import { inject, onActivated, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { signingSetting, signingColumns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import { companySelect } from '@/api/vehicle.js'
import { getStore } from '@/utils/store'
import { driverList, exportDriver } from '@/api/driver.js'
import { handleExport } from '@/utils/util'

import DriverForm from './form'
import DriverDetail from './detail'
import mobileForm from './mobileForm.vue'

const $message = inject('$message')
const userInfo = getStore({ name: 'userInfo' })

const formRef = ref(null)
const detailRef = ref(null)
const mobileRef = ref(null)
const settingRef = ref(signingSetting)

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList() {
	let query = params.value
	if (!query.status) {
		query.status = [1, 2]
	}
	if (!query.status.length) {
		query.status = [1, 2]
	}
	const payload = { ...query, page: page.value }
	const service = driverList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setTable(data.records)
		setTotal(data.total)
	}
}
function onEdit(row) {
	formRef.value.open(row)
}
function onEditMobile(row) {
	mobileRef.value.open(row)
}
function onDetail(val) {
	detailRef.value.open(val.driverId)
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

async function onExport() {
	setLoading(true)
	try {
		let query = params.value
		if (!query.status) {
			query.status = [1, 2]
		}
		if (!query.status.length) {
			query.status = [1, 2]
		}
		const res = await exportDriver({ ...query })
		if (res) handleExport(res, '签约司机')
	} catch (err) {
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
async function fetchSelect() {
	console.log('userInfo', userInfo)
	const { data, err } = await useFilter(() => companySelect(true), {
		name: 'companyName',
		code: 'companyId',
	})
	if (err) return $message.error(err.msg || err.message)
	const company = findItem('companyId')
	company.options = data
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	console.log('event', event)
	setParams(event)
	fetchList()
}

async function fetchDict() {
	const dict = await useDict(['driver_status', 'sex', 'order_status'])
	if (dict) setDict(dict)
	const status = findItem('status')
	status.options = [
		{ code: 1, name: '待履约' },
		{ code: 2, name: '履约中' },
	] // dict.driver_status.map(item => ({ name: item.name, code: item.code }))
}
function init() {
	fetchSelect()
	fetchDict()
	fetchList()
	const route = useRoute()
	const query = route.query
	params.value.status = [1, 2]
	const ins = getCurrentInstance()

	if (query) {
		if (Object.keys(query).length > 0) {
			params.value.keyword = query.keyword
			params.value.companyId = query?.companyIds?.split(',') || []
			ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
		}
		if (query.driverId) {
			onDetail({ driverId: query.driverId })
		}
	} else {
		ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
	}
}
onActivated(() => {
	init()
})
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.cursor {
		cursor: pointer;
		color: #409eff;
	}
}
</style>
