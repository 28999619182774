<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		title="修改手机"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-if="visible" v-loading="isLoading">
			<yi-form
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			></yi-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { telephoneAndMobileReg } from '@/utils/regUtil.js'
import { updateMobile } from '@/api/driver'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])

const form = [
	{
		prop: 'mobile',
		label: '原手机号',
		component: 'el-input',
		attrs: { placeholder: '', disabled: true },
	},
	{
		prop: 'newMobile',
		label: '修改手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入手机号', maxlength: 11 },
	},
]
const formList = ref(form)
const { visible, setVisible } = useModal()
const [model, setModel] = useState({
	newMobile: '',
})
const { isLoading } = useFetch()

const rules = {
	newMobile: [{ pattern: telephoneAndMobileReg, message: '请输入正确格式手机号', trigger: 'blur' }],
}
const formRef = ref(null)

function open(data) {
	console.log('data', data)
	setModel({ ...data })
	setVisible(true)
	console.log(model.value)
}

function onClose() {
	setVisible(false)
	setModel({})
}
async function onSubmit() {
	await formRef.value.validate()
	try {
		const { newMobile, driverId } = model.value
		const params = { newMobile, driverId }
		const service = updateMobile
		const res = await service(params)
		if (res.code !== 200) throw new Error(res.msg || res.message)
		$message.success('修改成功')
		emit('on-refresh')
		onClose()
	} catch (e) {
		console.log('e', e)
		$message.error(e.message)
	}
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.img-box {
	.flexible(row,center, flex-start);
}
.row {
	.flexible(row, center, flex-start);
}
</style>
